import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAPI from '../../api/apiHandler';
import { setLoading } from '../../redux/states/statesActions';
const AuthPasswordHook = (props) => {
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const loading = useSelector(state => state.states.loading);
  const [invalidList, setInvalidList] = useState([]);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({
    fullName: '',
    phoneNr: '',
    ssn: '',
    email: '',
    confirmEmail: '',
    address1: '',
    address2: '',
    message: '',
    postNr: '',
    city: '',
  })

  const validator = () => {
    let invalid = [];
    let keys = Object.keys(data);
    keys.forEach(key => {
      if (key !== 'address2' && key !== 'message') {
        if (data[key].length === 0) {
          invalid.push(key)
        }
      }
    });
    if (data.email !== data.confirmEmail && !invalid.includes('confirmEmail')) {
      invalid.push('confirmEmail');
    }
    if (data.ssn.length != 10 && data.ssn.length != 12) {
      invalid.push('ssn')
    }
    setInvalidList(invalid);
    return invalid.length === 0
  }
  const handleSubmit = () => {
    if (validator()) {
      dispatch(setLoading(true))
      var formdata = new FormData();
      formdata.append("name", data.fullName);
      formdata.append("mail", data.email);
      formdata.append("phone", data.phoneNr);
      formdata.append("ssn", data.ssn);
      formdata.append("adress", data.address1 + ' ' + data.address2);
      formdata.append("zip_code", data.postNr);
      formdata.append("city", data.city);
      // formdata.append("country",  data.address2); //TODO: Field doesn't exist on mockups
      formdata.append("ref", "member");
      formdata.append("message", data.message);
      setRequest({
        url: 'applicant',
        method: 'POST',
        data: formdata,
        displayError: true
      }, false)
          .then(res => {
        if (res) {
          setSuccess(true);
        } else {
          setSuccess(false);
        }
        dispatch(setLoading(false))
      })
        .catch(e => dispatch(setLoading(false)))
    }
  }
  const handleChange = (value, id) => {
    setData({ ...data, [id]: value })
  }
  return {
    data,
    invalidList,
    success,
    handleSubmit,
    handleChange,
    loading
  };
}

export default AuthPasswordHook;